.form_box {
  display: flex;
  justify-content: space-between;
  div {
    flex-grow: 1;
  }
}
.Space_box{
  margin: 0px 0 10PX;
}
@primary-color: #1DA57A;