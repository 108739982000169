.button_box {
  Button {
    margin-right: 10px;
  }
}
.ant-modal-footer .ant-btn {
  display: none;
}
.tab_but {
  width: 100%;
  text-align: right;
  Button {
    margin-right: 10px;
  }
}

@primary-color: #1DA57A;