.login-container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 550px;
	height: 100%;
	min-height: 100vh;
	background-color: #eeeeee;
	background-image: url("../../assets/images/login_bg.svg");
	background-position: 50%;
	background-size: 100% 100%;
	background-size: cover;
	.login-box {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 96%;
		height: 94%;
		padding: 0 4% 0 20px;
		overflow: hidden;
		background-color: hsl(0deg 0% 100% / 80%);
		border-radius: 10px;
		.login-left {
			width: 750px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.login-form {
			padding: 40px 45px 25px;
			border-radius: 10px;
			box-shadow: 2px 3px 7px rgb(0 0 0 / 20%);
			.login-logo {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 40px;
				.login-icon {
					width: 70px;
				}
				.logo-text {
					padding-left: 25px;
					font-size: 25px;
					font-weight: bold;
					color: #475768;
					white-space: nowrap;
				}
			}
			.ant-form-item {
				height: 75px;
				margin-bottom: 0;
				.ant-input-prefix {
					margin-right: 10px;
				}
				.ant-input-affix-wrapper-lg {
					padding: 8.3px 11px;
				}
				.ant-input-affix-wrapper,
				.ant-input-lg {
					font-size: 14px;
				}
				.ant-input-affix-wrapper {
					color: #bfbfbf;
				}
			}
			.login-btn {
				width: 100%;
				margin-top: 10px;
				white-space: nowrap;
				.ant-form-item-control-input-content {
					display: flex;
					justify-content: space-between;
					.ant-btn {
						width: 180px;
						span {
							font-size: 14px;
						}
					}
					.ant-btn-default {
						color: #606266;
					}
				}
			}
		}
	}
}
.code{
 height: 100%;
 width: 100%;
 color: #FFFFFF;
 text-align: center;
 line-height: 40px;
 border: 1px solid rgb(0 0 0 / 20%);
 background: url(../../assets//images//codebg.png) repeat-x 50% 50% / 100% 100%;
 font-size: 20px;
 font-weight: bold;
 text-decoration:underline;
 letter-spacing: 13px;
 border-radius: 3px;
 font-style:oblique;
 font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.pointer_cursor {
	cursor: pointer !important;
  }
@primary-color: #1DA57A;