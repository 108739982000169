body {
  margin: 0;
  min-width: 800px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  min-width: 800px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track-piece {
  /* //滚动条凹槽的颜色，还可以设置边框属性 */
  background-color: #ffff;
}

::-webkit-scrollbar {
  /* //滚动条的宽度 */
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  /* //滚动条的设置 */
  background-color: #ccc;
  background-clip: padding-box;
  height: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.pointer_cursor {
  cursor: pointer !important;
}
/* 禁止复制 */
.none_selet{
  -moz-user-select:none; /* Firefox私有属性 */

-webkit-user-select:none; /* WebKit内核私有属性 */

-ms-user-select:none; /* IE私有属性(IE10及以后) */

-khtml-user-select:none; /* KHTML内核私有属性 */

-o-user-select:none; /* Opera私有属性 */

user-select:none; /* CSS3属性 */
}
.se_footr{
  text-align: center;
  margin: 20px 0;
}