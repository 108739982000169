#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_box_right{
  margin-right: 20px;
}
.header_box_right_box {
  display: flex;
  align-items: center;
  height: 64px;
  vertical-align: middle;
  div {
    margin-right: 20px;
  }
}

@primary-color: #1DA57A;