
.Space_box{
  margin: 0px 0 10PX;
}
.addFrom_box{
  height: 65vh;
  overflow: hidden;
  overflow-y: auto;
}
.TextArea{
  height: 200px;
}
// .rule-btn{
//   margin-left: 1123px;
// }
.state-sel{
  width: 199px;
}
@primary-color: #1DA57A;