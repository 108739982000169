
.Space_box{
  margin: 0px 0 10PX;
}
.addFrom_box{
  height: 65vh;
  overflow: hidden;
  overflow-y: auto;
}
.TextArea{
  height: 200px;
}
.state-sle{
  width: 199px;
}
.add-btn{
  width: 137px;
}
// .m-l-1184{
//   margin-left: 1184px;
// }
@primary-color: #1DA57A;