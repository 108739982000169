.button_box {
  Button {
    margin-right: 10px;
  }
}
.ant-modal-footer .ant-btn {
  display: none;
}
.tab_but {
  width: 100%;
  text-align: right;
  Button {
    margin-right: 10px;
  }
}

.tree_but_box {
  width: 100%;
  height: 100px;
  line-height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  Button {
    margin-right: 20px;
  }
}

@primary-color: #1DA57A;