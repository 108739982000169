
.Space_box{
  margin: 0px 0 10PX;
}
.addFrom_box{
  height: 65vh;
  overflow: hidden;
  overflow-y: auto;
}
.TextArea{
  height: 200px;
}
.card_p_order{
  display: flex;
  justify-content: space-between;
}
.from_fh_but{
  width: 100%;
  text-align: center;
}
.select_state_box{
  width: 200px;
}
@primary-color: #1DA57A;