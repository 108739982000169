
.Space_box{
  margin: 0px 0 10PX;
}
.addFrom_box{
  height: 65vh;
  overflow: hidden;
  overflow-y: auto;
}
.TextArea{
  height: 200px;
}
.button_box{
  display: flex;
}
@primary-color: #1DA57A;