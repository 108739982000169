.title {
  width: 100px;
}
.flex {
  margin-top: 20px;
  display: flex;
  input {
    width: 500px;
  }
  .ql-editor {
    min-height: 150px;
    width: 70vw;
  }
}
.edit_box {
  position: relative;
}
.min {
  height: 80vh;
  overflow-y: auto;
}
.zw_box {
  height: 60px;
  width: 100%;
}
.foot {
  text-align: right;
  top: 0;
  position: absolute;
  right: 0;
  z-index: 99;
}
.span{
  color: red;
}
.goodsFoot{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
}
.gui_gei_box{
  justify-content: space-between;
  width: 185px;
}
@primary-color: #1DA57A;